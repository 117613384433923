import { useEffect } from "react"
import { navigate } from "gatsby"

export const useScript = src => {
  useEffect(() => {
    const loadScript = () => {
      let existingScript = document.querySelector(`script[src="${src}"]`)
      if (!existingScript) {
        const script = document.createElement("script")
        script.src = src
        script.async = true
        document.body.appendChild(script)

        // Call Instagram reprocess on script load
        script.onload = () => {
          if (window.instgrm) {
            window.instgrm.Embeds.process()
          }
        }
      } else {
        // Reinitialize Instagram embeds if script exists
        if (window.instgrm) {
          window.instgrm.Embeds.process()
        }
      }
    }

    // Load the script on initial mount
    loadScript()

    const onRouteChange = () => {
      // Reinitialize Instagram embeds on route changes
      if (window.instgrm) {
        window.instgrm.Embeds.process()
      }
    }

    // Gatsby doesn't expose route change events directly
    // We listen to "popstate" which is triggered by Gatsby navigation
    window.addEventListener("popstate", onRouteChange)

    return () => {
      // Cleanup the event listener
      window.removeEventListener("popstate", onRouteChange)
    }
  }, [src])
}
